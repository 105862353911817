import { Form } from 'ant-design-vue';
import { IformCreateOption, WrappedFormUtils } from 'ant-design-vue/types/form/form';
import { ref, onMounted, onUnmounted, getCurrentInstance, onBeforeMount  } from 'vue';
let keyName = 0;
export function useForm(options?: IformCreateOption) {
    onBeforeMount(() => {
        const newForm = (Form as any)?.createForm(getCurrentInstance()?.proxy, {
            name: 'form' + keyName++,
            ...options
        });
        function getFormValue() {
            let value: any = {};
            if (form.value) {
                form.value.validateFields((err, values) => {
                    // if (!err) {
                    value = values;
                    // }
                });
            }
            return value;
        }
        newForm.getFormValue = getFormValue;

        form.value = newForm
    })
    const form = ref<
        WrappedFormUtils &
        {
            /**获取表单数据 */
            getFormValue: () => any;
        }
    >((Form as any)?.createForm(getCurrentInstance()?.proxy, {
        name: 'form',
        ...options
    }));

    return form;
}
