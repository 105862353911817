
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { getValidatorMap, Validate, Validator } from '@/mixin/validator';
import { setDays, getDays, SettingDealParams } from '@/api/setting/set-up-transactions';
import { changeLoading } from '@/util/decorators';
@Component
export default class CategoryList extends Mixins(Validate) {
  is_loading: boolean = false;
  form: SettingDealParams = {
    auto_confirm_day: 7,
    confirm_return_day: 0,
  };
  validator_list: any[] = [
    {
      field: 'auto_confirm_day',
      validate: (value: any) => value >= 0,
    },
    {
      field: 'confirm_return_day',
      validate: (value: any) => value >= 0,
    },
  ];
  validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);
  disable: boolean=false  //小数提示框是否显示
  handleNumberChange(value: number) {
    const res=/(^[0-9]\d*$)/ //正整数正则
    //const ret=/^(-?\d+)(\.\d{1,2})?$/  //正则定义两位小数
    //if(types=='xs'){return}
    if((value!=null&&String(value)!='')&&(value<0||!res.test(String(value)))){
      this.disable=true
    }else{
      this.disable=false
    }
    //this.value = value;
  }

  @Watch('form.auto_confirm_day')
  onDaysChange(newVal: number) {
    this.form.auto_confirm_day = ~~newVal;
  }

  async save() {
    if (!this.validateCommit()||this.disable) {
      return;
    }
    const res = await setDays(this.form);
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.$message.success('设置成功');
    this.getDays();
  }
  @changeLoading(['is_loading'])
  async getDays() {
    const res = await getDays();
    console.log(res);

    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.form.auto_confirm_day = res.data.auto_confirm_day;
    this.form.confirm_return_day = res.data.confirm_return_day || 0;
  }
  @changeLoading(['is_loading'])
  async created() {
    this.$route;
    await this.getDays();
  }
}
