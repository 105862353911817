
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CustomModal extends Vue {
  @Prop({ type: String, default: '' }) title!: string;
  @Prop({ type: Boolean, default: false }) visible!: boolean;
  @Prop({ type: String, default: '取消' }) cancelText!: string;
  @Prop({ type: String, default: '确定' }) okText!: string;
  @Prop({ type: String, default: 'primary' }) okType!: string;
  @Prop({ type: Number, default: 520 }) width!: number;
  @Prop({ type: Number, default: 10 }) zIndex!: number; // 模态窗的z-index
  @Prop({ type: Boolean, default: true }) isShowCancel!: boolean; // 是否显示取消按钮
  @Prop({ type: Boolean, default: true }) destroyOnClose!: boolean; // 销毁里面的元素
  @Prop({ type: Boolean, default: true }) maskClosable!: boolean;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: Boolean, default: true }) isShowFooter!: boolean; // 是否显示底部按钮

  handleCancel() {
    this.$emit('cancel');
  }

  handleOk() {
    this.$emit('ok');
  }
}
