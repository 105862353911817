import { request as axios } from '@/util/request';
import { message } from 'ant-design-vue';
const getUrl = require('baimahu/url-with-param');
const API = {
  account_info: '/boss/setting/account',
  privilege_list: '/boss/setting/privilege/list',
};

// 用户信息数据接口
export interface UserInfo<T> {
  create_time: number;
  id: number;
  is_enable: number;
  mobile: string;
  name: string;
  privilege: T;
  update_time: number;
}
// 初始化用户信息数据
export function initUserInfo(remote?: UserInfo<string>): UserInfo<number[]> {
  remote = remote || ({} as UserInfo<string>);
  return {
    create_time: remote.create_time || 0,
    id: remote.id || 0,
    is_enable: remote.is_enable || 0,
    mobile: remote.mobile || '',
    name: remote.name || '',
    privilege: remote.privilege ? remote.privilege.split(',').map(Number) : [] || '',
    update_time: remote.update_time || 0,
  };
}
// 获取用户信息
export async function getAccountPrivilege(id: number) {
  return await axios.get(`${API.account_info}/${id}`);
}

// 权限列表数据接口
export interface PrivilegeData {
  parent_indeterminate: boolean;
  indeterminate: boolean;
  id: number;
  name: string;
  parent: PrivilegeData[];
  parent_id: number;
  rule: string;
  sort: number;
  status: number;
}
// 初始化权限列表
export function initPrivilegeList(remote?: PrivilegeData[]): PrivilegeData[] {
  remote = remote || [];
  return remote;
}
// 获取权限列表
export async function getPrivilegeList() {
  return await axios.get(`${API.privilege_list}`);
}

// 编辑用户权限
export async function editAccountPrivilege(id: number, privilege: string) {
  const res = await axios.put(`${API.account_info}/${id}`, {
    privilege,
  });
  if (res.status === 200) {
    message.success('修改成功');
    return true;
  }
  message.error('修改失败');

  return false;
}
