import { request as axios } from '@/util/request';

const API = {
  set_days: '/boss/setting/deal/99999',
};

export interface SettingDealParams {
  auto_confirm_day: number;
  confirm_return_day: number;
}
export async function setDays(params: SettingDealParams) {
  return await axios.put(API.set_days, params);
}

export async function getDays() {
  return await axios.get(API.set_days);
}
