
import { Component, Vue, Watch } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import {
  editAccountPrivilege,
  getAccountPrivilege,
  getPrivilegeList,
  initUserInfo,
  initPrivilegeList,
  UserInfo,
  PrivilegeData,
} from '@/api/setting/edit-account';
@Component
export default class ListTemplate extends Vue {
  is_loading: boolean = false;
  privilege_list: PrivilegeData[] = initPrivilegeList();
  user_id: number = 0;
  user_info: UserInfo<number[]> = initUserInfo();

  // 提交
  @changeLoading(['is_loading'])
  async submit() {
    const res: any = await editAccountPrivilege(this.user_id, this.user_info.privilege.join(','));
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.$message.success('编辑成功');
  }

  @Watch('user_info', { deep: true })
  changePrivilege() {
    for (let i = 0; i < this.privilege_list.length; i++) {
      let count = 0;
      let parent_indeterminate_count = 0;
      for (let j = 0; j < this.privilege_list[i].parent.length; j++) {
        let count2 = 0;
        let indeterminate_count = 0;
        if (this.user_info.privilege.indexOf(this.privilege_list[i].parent[j].id) === -1) {
          count++;
        } else {
          parent_indeterminate_count++;
        }
        for (let k = 0; k < this.privilege_list[i].parent[j].parent.length; k++) {
          if (
            this.user_info.privilege.indexOf(this.privilege_list[i].parent[j].parent[k].id) === -1
          ) {
            count2++;
          } else {
            indeterminate_count++;
          }
        }
        if (
          count2 === this.privilege_list[i].parent[j].parent.length &&
          this.user_info.privilege.indexOf(this.privilege_list[i].parent[j].id) !== -1
        ) {
          this.user_info.privilege.splice(
            this.user_info.privilege.indexOf(this.privilege_list[i].parent[j].id),
            1
          );
        }
        if (
          indeterminate_count !== 0 &&
          indeterminate_count !== this.privilege_list[i].parent[j].parent.length
        ) {
          this.privilege_list[i].parent[j].indeterminate = true;
        } else {
          this.privilege_list[i].parent[j].indeterminate = false;
        }
      }
      if (
        count === this.privilege_list[i].parent.length &&
        this.user_info.privilege.indexOf(this.privilege_list[i].id) !== -1
      ) {
        this.user_info.privilege.splice(
          this.user_info.privilege.indexOf(this.privilege_list[i].id),
          1
        );
      }
      let all=0
      let counts=0;
      for(let k=0;k<this.privilege_list[i].parent.length;k++){
          for(let l=0;l<this.privilege_list[i].parent[k].parent.length;l++){
            all++;
            if(this.user_info.privilege.indexOf(this.privilege_list[i].parent[k].parent[l].id)!==-1){
              counts++;
            }
          }
      }
      if(parent_indeterminate_count==0){
        this.privilege_list[i].parent_indeterminate=false
      }else{
        if(parent_indeterminate_count==this.privilege_list[i].parent.length&&counts==all){
          this.privilege_list[i].parent_indeterminate=false;
        }else{
          this.privilege_list[i].parent_indeterminate=true;
        }
      }
      // if (
      //   parent_indeterminate_count !== 0 &&
      //   parent_indeterminate_count !== this.privilege_list[i].parent.length&&counts!==all
      // ) {
      //   this.privilege_list[i].parent_indeterminate = true;
      // } else {
      //   this.privilege_list[i].parent_indeterminate = false;
      // }
    }
  }

  changeCheckBox(e: any, item: any, level: number) {
    if (e.target.checked) {
      this.user_info.privilege.push(item.id);
      if (level === 1) {
        item.parent.forEach((item2: any) => {
          this.user_info.privilege.push(item2.id);
          item2.parent.forEach((item3: any) => {
            this.user_info.privilege.push(item3.id);
          });
        });
      }
      if (level === 2) {
        this.user_info.privilege.push(item.parent_id);
        item.parent.forEach((item2: any) => {
          this.user_info.privilege.push(item2.id);
        });
      }
      if (level === 3) {
        this.user_info.privilege.push(item.parent_id);
        this.privilege_list.forEach((item2: any) => {
          item2.parent.forEach((item3: any) => {
            if (item3.id === item.parent_id) {
              this.user_info.privilege.push(item2.id);
            }
          });
        });
      }
      this.user_info.privilege = [...new Set(this.user_info.privilege)];
    } else {
      if (level === 1) {
        const arr: any[] = [];
        item.parent.forEach((item: any) => {
          arr.push(item.id);
          item.parent.forEach((item: any) => {
            arr.push(item.id);
          });
        });
        this.deleteId(item, arr);
      }
      if (level === 2) {
        const arr = item.parent.map((item: any) => {
          return item.id;
        });
        this.deleteId(item, arr);
      }
      if (level === 3) {
        this.deleteId(item);
      }
    }
  }

  deleteId(item: any, arr: any = []) {
    this.user_info.privilege.splice(this.user_info.privilege.indexOf(item.id), 1);
    arr.forEach((item: any) => {
      if (this.user_info.privilege.indexOf(item) !== -1) {
        this.user_info.privilege.splice(this.user_info.privilege.indexOf(item), 1);
      }
    });
  }

  // 获取所有权限列表
  @changeLoading(['is_loading'])
  async getPrivilegeList() {
    const res: any = await getPrivilegeList();
    this.privilege_list = initPrivilegeList();
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.privilege_list = initPrivilegeList(res.data);
    this.privilege_list =JSON.parse(JSON.stringify(this.privilege_list))
    this.privilege_list[2].parent.splice(1,1)
    this.privilege_list[7].parent.splice(1,1)
  }

  // 获取用户信息
  @changeLoading(['is_loading'])
  async getAccountPrivilege(id: number) {
    const res: any = await getAccountPrivilege(id);
    this.user_info = initUserInfo();
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.user_info = initUserInfo(res.data);
  }
  @changeLoading(['is_loading'])
  async created() {
    this.user_id = +this.$route.query.id;
    await this.getAccountPrivilege(this.user_id);
    await this.getPrivilegeList();
    this.privilege_list.forEach((item: any) => {
      item.parent_indeterminate = false;
      item.parent.forEach((item2: any) => {
        item2.indeterminate = false;
      });
    });
    this.user_info=JSON.parse(JSON.stringify(this.user_info));
  }
}
