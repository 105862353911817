import { defineStore } from 'pinia'
import { ref, reactive } from 'vue'

interface Pagination {
    [key: string]: any;
}

export const useMain = defineStore('main', () => {
    const pagination: Pagination = reactive({})

    return { pagination }
})
