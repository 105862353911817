import { PaginationData, request, ServiceReturn  } from '@/util/request';
import { PointsProduct, PointsSetting, Task } from '@/view/marketing/type/points';
import { message } from 'ant-design-vue';
export async function getPointsSetting() {
    const url = `/boss/setting/exchange`;
    const res = await request.get<PointsSetting>(url);
    if (res.status == 200) {
        return res.data;
    } else {
        return undefined;
    }
}
export async function setPointsSetting(data: PointsSetting) {
    const url = `/boss/setting/exchange`;
    const res = await request.post(url, data);
    if (res.status == 200) {
        message.success('编辑成功');

        return res.data;
    } else {
        message.error('编辑失败');

        return [];
    }
}

export async function getPointsTask() {
    const url = `/boss/setting/exchange`;
    const res = await request.get<Task[]>(url);
    if (res.status == 200) {
        return res.data || [];
    } else {
        return [];
    }
}

export async function postPointsTask(
    id,
    data: {
        /**每日获取次数上限*/
        daily_times: number;
        /** 扩展 */
        extend: any;
        /**获取积分*/
        points: number;
        /** 状态，1开启0关闭*/
        status: number;
    }
) {
    const url = `/boss/setting/exchange/${id}`;
    const res = await request.put(url, data);
    console.log(res);

    if (res.status == 200) {
        message.success('编辑成功');

        return true;
    } else {
        message.error('编辑失败');

        return false;
    }
}

export async function getPointsRule() {
    const url = '/boss/setting/exchange';
    const res = await request.get<{ rule: string }>(url);
    if (res.status == 200) {
        return res.data;
    } else {
        return undefined;
    }
}

export async function postPointsRule(rule: string) {
    const url = '/boss/setting/exchange';
    const res = await request.post<number>(url, { rule });
    if (res.status == 200 && res.data == 1) {
        message.success('编辑成功');

        return true;
    } else {
        message.error('编辑失败');

        return false;
    }
}
export async function postPointsProduct(
    params: { title?: string; current?: number } = {
        current: 1,
    }
) {
    const url = `/boss/setting/exchange`;
    const res = await request.get<PaginationData<PointsProduct>>(url, {
        params,
    });
    if (res.status == 200 && res.data) {
        return res.data;
    } else {
        return res.data;
    }
}
export async function delPointsProduct(id: number) {
    const url = `/boss/setting/exchange/${id}`;
    const res = await request.delete(url);
    if (res.status == 200 && res.data) {
        message.success('删除成功');
        return res.data;
    } else {
        message.error('删除失败');
        return res.data;
    }
}
export async function createPointsProduct(products: {
    title: string;
    product_id: number;
    points: number;
}[]) {
    const url = `/boss/setting/exchange`;
    const res = await request.post(url, { data: products });
    if (res.status == 200 && res.data) {
        message.success('新建成功');
        return res.data;
    } else {
        message.error('新建失败');
        return res.data; 
    }
}
export async function editPointsProduct(id: number, points: number) {
    const url = `/boss/setting/exchange/${id}`;
    const res = await request.put(url, { points });
    if (res.status == 200 && res.data) {
        message.success('编辑成功');
        return res?.data;
    } else {
        message.error('编辑失败');
        return res?.data;
    }
}