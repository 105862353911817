
import { Component, Mixins } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import { getValidatorMap, Validate } from '@/mixin/validator';
import CustomModal from '@/component/custom-modal.vue';
import {useMain} from '@/pinia/index'
import { Form } from 'ant-design-vue';
import NDrawer from '@/component/NDrawer/NDrawer.vue'
import AcconuntAuthority from './account/AccountAuthority.vue';

import {
  activateOrDisable,
  addAccount,
  getAccountListData,
  getAccountList,
  PaginationData,
  GetAccountListSendData,
  exportData,
} from '@/api/setting/account';

const ACCOUNT_STATUS = [
  {
    id: 0,
    value: '全部',
  },
  {
    id: 1,
    value: '正常',
  },
  {
    id: 2,
    value: '停用',
  },
];

@Component({
  name:'SettingAccount',
  components: {
    CustomModal,
    NDrawer,
    AcconuntAuthority
  },
})
export default class SettingAccount extends Mixins(Validate) {
  account_list_data: PaginationData = getAccountListData(); // 账户列表
  searchForm: any
  form_data: any = {
    is_enable: 0,
  }; // 搜索表单和新增模态窗中的数据
  form: any = {
    modal_name: '',
    modal_mobile: '',
  };
  form_item_layout: any = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  };
  is_loading: boolean = false;
  is_show_add_account_modal: boolean = false; // 是否展示新增用户模态窗
  validator_list: any = [
    {
      field: 'modal_name',
      message: '请填写姓名',
    },
    {
      field: 'modal_mobile',
      validate: (value: string) => !!value && value.length === 11,
      message: '请填写手机号',
    },
  ];
  validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);
  pagination = useMain().pagination
  get accountStatus() {
    return ACCOUNT_STATUS;
  }
  @changeLoading(['is_loading'])
  async handleSubmit(e){
    e.preventDefault();
    this.searchForm.validateFields(async (err, values) => {
      if (!err) {
        this.form_data = {
          ...values
        }
        await this.getAccountList({...values,current:1});
      }
    });
    return false
    
  }
  // 清空搜索条件
  @changeLoading(['is_loading'])
  async clearSearch() {
    this.form_data.name = '';
    this.form_data.mobile = '';
    this.form_data.is_enable = 0;
    this.searchForm.resetFields()
    await this.getAccountList()
  }

  paginationOption(data: any) {
    return {
      current: +data.current || 0,
      total: data.total || 0,
      pageSize: data.limit || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
    };
  }

  // 新增账号
  @changeLoading(['is_loading'])
  async addAccount() {
    if (this.validatorMap.modal_mobile.status === 'error') {
      if (this.form.modal_mobile) {
        this.validatorMap.modal_mobile.message = '请填写正确的手机号';
      } else {
        this.validatorMap.modal_mobile.message = '请填写手机号';
      }
    }
    if (!this.validateCommit()) {
      return;
    }
    const res = await addAccount(this.form.modal_name, this.form.modal_mobile);
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.is_show_add_account_modal = false;
    this.form.modal_name = '';
    this.form.modal_mobile = '';
    await this.getAccountList();
    this.$message.success('新增成功');
  }
  // 停用或启用账户
  @changeLoading(['is_loading'])
  async onChangesAccountStatus(record: any) {
    const message = this.$message;
    const getAccountList = this.getAccountList;
    const form_data = this.form_data
    const account_list_data = this.account_list_data
    this.$confirm({
      title: '',
      content: `确定${record.is_enable === 1 ? '停用' : '启用'} ${record.name} 账号?`,
      iconType: '',
      async onOk() {
        const res = await activateOrDisable(record.id, record.is_enable === 1 ? 2 : 1);
        if (res.status !== 200) {
          message.error((res as any).message);
          return;
        }
        getAccountList({
          name: form_data.name,
          mobile: form_data.mobile,
          is_enable: form_data.is_enable ? form_data.is_enable : '',
          current: account_list_data.current as number
        });
        message.success(`${record.is_enable === 1 ? '停用' : '启用'}成功`);
      },
      onCancel() {
        '';
      },
    });
  }

  // 关闭新增账号模态窗
  closeModal() {
    this.is_show_add_account_modal = false;
    this.form.modal_name = '';
    this.form.modal_mobile = '';
  }

  // 点击搜索按钮
  @changeLoading(['is_loading'])
  async clickSearchBtn() {
    const send_data = {
      current: 1,
      name: this.form_data.name,
      mobile: this.form_data.mobile,
      is_enable: this.form_data.is_enable ? this.form_data.is_enable : '',
    };
    await this.getAccountList(send_data);
  }
  @changeLoading(['is_loading'])
  async handleChange(data: any) {
    const send_data = {
      current: data.current,
      name: this.form_data.name,
      mobile: this.form_data.mobile,
      is_enable: this.form_data.is_enable ? this.form_data.is_enable : '',
    };
    await this.getAccountList(send_data);
  }

  // 导出数据
  async exportData() {
    try {
      const r: any=await exportData({
      is_export: 1,
      current: +this.account_list_data.current,
      name: this.form_data.name,
      mobile: this.form_data.mobile,
      is_enable: this.form_data.is_enable ? this.form_data.is_enable : '',
    });
      if(r.status===402){
        this.$message.error(r.message)
      }
    } catch (error) {
      window.location.href=`https:${error}`
    }
  }

  // 获取账户列表
  async getAccountList(
    send_data: GetAccountListSendData = {
      current: 1,
    }
  ) {
    const res = await getAccountList(send_data);
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.account_list_data = getAccountListData(res.data);
  }
  oldSearchData(path: string){
    this.pagination.account = {
      ...this.form_data,
      current: this.account_list_data.current
    }
    this.$router.push(path)
  }
  @changeLoading(['is_loading'])
  async created() {
    this.searchForm = (Form as any)?.createForm(this, {
      name: 'form',
    });
    if(this.pagination?.account){
      Object.keys(this.pagination.account).forEach((item)=>{
        if(item !== 'current'){
          this.form_data[item] = this.pagination.account[item]
        }
      })
      await this.getAccountList(this.pagination?.account);
      delete this.pagination.account
      this.searchForm.getFieldDecorator('is_enable',{
        initialValue:this.form_data.is_enable
      })
      return
    }
    this.searchForm.getFieldDecorator('is_enable',{
      initialValue:this.form_data.is_enable
    })
    await this.getAccountList();
  }
}
