
import { useMainElement } from '@/pinia/modules/mainElement';
import { storeToRefs } from 'pinia';
import { SetupContext, defineComponent, ref, watch } from 'vue';

export default {
  props: {
    cache: {
      type: Boolean,
      default: false,
    },
    inPage: {
      type: Boolean,
      default: false,
    },
  },
  setup(props,{emit}: SetupContext) {
    const { element } = storeToRefs(useMainElement());
    let el = null as any;
    function getContainer() {
      if(el){
        return el
      }
      el =  props?.inPage ? element.value : document.body;
      return el
    }
    // const emit = defineEmits(['open', 'close']);
    const visible = ref(false);

    function onClose() {
      visible.value = !visible.value;
    }
    const open = () => {
      visible.value = true;
    };
    watch(visible, val => {
      val ? emit('open', val) : emit('close', val);
    });
    return {
      onClose,
      open,
      visible,
      getContainer,
    };
  },
}
