import { request as axios } from '@/util/request';
import { message } from 'ant-design-vue';
import fileDownload from 'js-file-download';

const clean = require('bmh/clean-empty-obj');
const getUrl = require('baimahu/url-with-param');
const { VUE_APP_API: FCREST } = process.env;
const API = {
  get_account_list: '/boss/setting/account',
  add_account: '/boss/setting/account',
  activate_or_disable: '/boss/setting/account/',
  export: `${FCREST}/boss/setting/account/export`
};


// 请求用户列表参数数据接口
interface GetAccountListSendData {
  current: number;
  name?: string;
  mobile?: string;
  is_enable?: number;
  is_export?: number;
}
// 用户列表返回数据接口
interface PaginationData {
  current: string | number;
  detail: AccountData[];
  last: number;
  limit: number;
  total: number;
}
// 用户数据接口
interface AccountData {
  create_time: number;
  id: number;
  is_enable: number;
  mobile: string;
  name: string;
  update_time: string;
}
// 初始化or获取数据
function getAccountListData(remote?: PaginationData): PaginationData {
  remote = remote || {} as PaginationData;
  return {
    current: remote.current || 1,
    detail: remote.detail || [],
    last: remote.last || 0,
    limit: remote.limit || 0,
    total: remote.total || 0,
  }
}
// 获取账号列表
async function getAccountList(send_data?: GetAccountListSendData) {
  const url = getUrl(API.get_account_list, send_data);
  return await axios.get(url);
}

// 新增账号
async function addAccount(name: string, mobile: string) {
  return await axios.post(API.add_account, {
    name,
    mobile,
  });
}

// 导出
export async function exportData(send_data?: GetAccountListSendData) {
  const url = getUrl(API.export, send_data);
  return new  Promise((resolve,reject)=>{
    axios.get(`https:${url}`, { responseType: 'blob' }).then(res=>{
      fileDownload(res as any,'账户.xlsx');

      resolve(res)
    }).catch(()=>{
      reject(url);
    })
    }
  )
}

// 激活或者停用账号
async function activateOrDisable(id: number, is_enable: number) {
  return await axios.get(`${API.activate_or_disable}${id}/edit?id=${id}&is_enable=${is_enable}`);
}
export {
  getAccountList,
  PaginationData,
  getAccountListData,
  GetAccountListSendData,
  addAccount,
  activateOrDisable,
}

export async function getStaff(id) {
  try {
      const res = await axios.get(`/boss/setting/staff/${id}`)
      if (res.status === 200) {
          return res.data
      } else {
          message.error(res.message || '获取失败')
          return undefined
      }
  } catch (e) {
      console.log(e)
      return undefined
  }
}

export async function editStaff(id, privilege: string) {
  try {
      const res = await axios.put(`/boss/setting/staff/${id}`, {
          privilege
      })
      if (res.status === 200) {
          message.success('修改成功')
          return true
      } else {
          message.error('修改失败')
          return false
      }
  } catch (e) {
      console.log(e)
      message.error('修改失败')

      return false
  }
}