/**
 * 校验器混入对象，使用方法：
 * 1. 导入 mixin
 * 2. export default class *** extends Mixins(Validate) {}
 * 3. 使用 getValidatorMap 方法生成一个 validatorMap
 * 4. 在需要校验的时候调用 this.validateCommit() 即可
 * >>> 必须将需要校验的数据字段定义在 form 对象里 <<<
 * >>> 目前只适用于单个表单的校验 <<<
 * 在 ts 中使用 mixin 参考 https://class-component.vuejs.org/guide/extend-and-mixins.html#mixins
 */

import Vue from 'vue';
import Component from 'vue-class-component';

export interface Validator {
  field: string;
  validate: (value: any, form?: any) => boolean;
  message: string;
  status: 'success' | 'error';
  help: string;
}

const _ = {
  keyBy: require('lodash/keyBy'),
  each: require('lodash/forEach'),
  map: require('lodash/map'),
};

/**
 * 生成校验器对象， map 结构，键名是需要校验的字段名
 * 最终生成的结构：
 * {
 *    field: { field: '', message: '', validate: (value) => {}, status: 'success', help: '' }
 * }
 * @param list 需要校验的字段，一个数组对象：{ field: '需要校验的字段名', validate: 校验函数, message: '需要显示的提示信息', }
 */
export function getValidatorMap(list: any[] = []): { [field: string]: Validator } {
  const validator_list = list.map(
    (item): Validator => ({
      ...item,
      validate: item.validate ? item.validate : (value: string) => !!value, // 如果没有传入 validate，就会使用默认的校验规则
      status: 'success',
      help: '',
    })
  );
  return _.keyBy(validator_list, (item: Validator) => item.field);
}

@Component
export class Validate extends Vue {
  form: any = {}; // 表单对象，用来绑定各字段的数据
  validatorMap: { [field: string]: Validator } = getValidatorMap(); // 校验器对象
  field_watch_list: (() => void)[] = []; // 监听需要校验字段

  /**
   * 确认是否还有必填项未填
   */
  validateCommit() {
    let is_valid: boolean = true;
    _.each(this.validatorMap, (validator: Validator, field: string) => {
      if (!this.validate(field)) {
        is_valid = false;
      }
    });

    if (!is_valid) {
      // this.$message.error('请填入必填项');
    }
    return is_valid;
  }

  /**
   * 对传入的字段校验
   * @param field 字段名称
   */
  validate(field: string) {
    const is_valid = this.validatorMap[field].validate(this.form[field], this.form);
    this.validatorMap[field].status = is_valid ? 'success' : 'error';
    this.validatorMap[field].help = is_valid ? '' : this.validatorMap[field].message;
    return is_valid;
  }

  /**
   * 重置表单状态
   */
  resetValidatorStatus() {
    _.each(this.validatorMap, (validator: Validator) => {
      validator.help = '';
      validator.status = 'success';
    });
  }

  created() {
    // 监听需要校验的字段
    this.field_watch_list = _.map(this.validatorMap, (validator: Validator, field: string) =>
      this.$watch(`form.${field}`, () => {
        this.validate(field);
      })
    );
  }

  beforeDestroy() {
    // 取消监听
    this.field_watch_list.forEach((unwatch: () => void) => {
      if (unwatch) {
        unwatch();
      }
    });
  }
}
