
import { Component, Vue } from 'vue-property-decorator';
import CustomModal from '@/component/custom-modal.vue';
import storage from 'baimahu/storage';
import { storeToRefs } from 'pinia';

import { useUser } from '@/pinia/modules/userData';
const md5 = require('blueimp-md5');
const { VUE_APP_EXPIRED: EXPIRED } = process.env;
const { VUE_APP_API: FCAPI } = process.env;
const API = {
  captcha: `${FCAPI}/boss/profile/captcha`,
  password: `${FCAPI}/boss/profile/password`,
  edit_name: `${FCAPI}/boss/profile/staff`,
};
@Component({
  components: {
    CustomModal,
  },
})
export default class PersonalInformation extends Vue {
  // @Mutation(MUTATION_LOG_ON_OUT) logOnOut: any;
  // @Mutation(MUTATION_PROFILE_NAME) profileName: any;
  userData = storeToRefs(useUser()).userData;
  // 表单布局
  form_item_layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 18 },
  };

  modal_layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  };

  is_loading: boolean = false;
  edit_name_modal: boolean = false;
  set_password_modal: boolean = false;
  edit_password_modal: boolean = false;
  forget_password_modal: boolean = false;
  name: string = '';
  mobile: string = '';
  password: string = '';
  is_password_loading: boolean = false;
  form: any = {
    name: {
      data: '球球',
    },
    password: {},
    repeat_password: {},
    new_password: {},
    repeat_new_password: {},
    captcha: {},
  };
  auth_code: number = 60;
  profile: any = {};

  checkChange(field: any) {
    let error = '0';
    if (this.edit_name_modal) {
      if (field === 'name') {
        if (!this.form.name.data) {
          this.form.name.status = 'error';
          this.form.name.error = '请输入姓名';
          error = '1';
        } else {
          this.form.name.status = '';
          this.form.name.error = '';
        }
        return error;
      }
    }
    if (this.set_password_modal || this.edit_password_modal) {
      if (field === 'password') {
        if (!this.form.password.data) {
          this.form.password.status = 'error';
          this.form.password.error = this.set_password_modal
            ? '请输入登录密码'
            : '请输入原登录密码';
          error = '1';
        } else {
          this.form.password.status = '';
          this.form.password.error = '';
        }
        return error;
      } else if (field === 'repeat_password') {
        if (!this.form.repeat_password.data) {
          this.form.repeat_password.status = 'error';
          this.form.repeat_password.error = this.set_password_modal
            ? '请重复输入登录密码'
            : '请重复输入原登录密码';
          error = '1';
        } else if (this.form.repeat_password.data !== this.form.password.data) {
          this.form.repeat_password.status = 'error';
          this.form.repeat_password.error = '两次密码输入不一致，请检查后重新输入';
          error = '1';
        } else {
          this.form.repeat_password.status = '';
          this.form.repeat_password.error = '';
        }
        return error;
      }
    }
    if (this.edit_password_modal || this.forget_password_modal) {
      if (field === 'new_password') {
        if (!this.form.new_password.data) {
          this.form.new_password.status = 'error';
          this.form.new_password.error = '请输入新的登录密码';
          error = '1';
        } else {
          this.form.new_password.status = '';
          this.form.new_password.error = '';
        }
        return error;
      } else if (field === 'repeat_new_password') {
        if (!this.form.repeat_new_password.data) {
          this.form.repeat_new_password.status = 'error';
          this.form.repeat_new_password.error = '请重复输入新的登录密码';
          error = '1';
        } else if (this.form.repeat_new_password.data !== this.form.new_password.data) {
          this.form.repeat_new_password.status = 'error';
          this.form.repeat_new_password.error = '两次密码输入不一致，请检查后重新输入';
          error = '1';
        } else {
          this.form.repeat_new_password.status = '';
          this.form.repeat_new_password.error = '';
        }
        return error;
      }
    }
    if (this.forget_password_modal) {
      if (field === 'captcha') {
        if (!this.form.captcha.data) {
          this.form.captcha.status = 'error';
          this.form.captcha.error = '请输入验证码';
          error = '1';
        } else {
          this.form.captcha.status = '';
          this.form.captcha.error = '';
        }
        return error;
      }
    }
  }

  validator() {
    let is_valid = true;
    const valid: any = [];
    for (const key in this.form) {
      if (key) {
        valid.push(this.checkChange(key));
      }
    }
    this.$forceUpdate();
    if (valid.indexOf('1') !== -1) {
      is_valid = false;
    }
    return is_valid;
  }

  showEditName() {
    this.form.name.data = this.name;
    this.edit_name_modal = true;
  }

  cancelEditName() {
    this.form.name.data = this.name;
    this.form.name.status = '';
    this.form.name.error = '';
    this.edit_name_modal = false;
  }

  async editName() {
    if (!this.validator()) {
      return;
    }
    const res: any = await this.$axios.put(`${API.edit_name}/${this.profile.id}`, {
      name: this.form.name.data,
    });
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.$message.success('编辑成功');
    useUser().setUserData(this.profile)
    // this.profileName(this.form.name.data);
    this.profile.name = this.form.name.data;
    storage.setItem('profile', this.profile, EXPIRED); // 更新storage.profile
    this.name = this.form.name.data;
    this.edit_name_modal = false;
  }

  showEditpassword() {
    if (this.password) {
      this.edit_password_modal = true;
    } else {
      this.set_password_modal = true;
    }
  }

  cancelSetpassword() {
    this.cancelStatus();
    this.set_password_modal = false;
  }

  async setPassword() {
    if (!this.validator()) {
      return;
    }
    const res: any = await this.$axios.post(API.password, {
      password: md5(this.form.password.data).toUpperCase(),
      confirm_password: md5(this.form.repeat_password.data).toUpperCase(),
    });
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.$message.success('编辑成功');
    this.set_password_modal = false;
    this.password = '******';
  }

  cancelEditpassword() {
    this.cancelStatus();
    this.edit_password_modal = false;
  }

  async editPassword() {
    if (!this.validator()) {
      return;
    }
    const res: any = await this.$axios.put(`${API.password}/${this.profile.id}`, {
      type: 'edit',
      password: md5(this.form.password.data).toUpperCase(),
      new_password: md5(this.form.new_password.data).toUpperCase(),
    });
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.$message.success('修改成功，需重新登录');
    // this.logOnOut(false);
    storage.removeItem('profile');
    this.$router.push('/login');
    this.edit_password_modal = false;
  }

  showForgetPassword() {
    this.cancelEditpassword();
    this.forget_password_modal = true;
  }

  cancelStatus() {
    this.form.password.data = '';
    this.form.password.status = '';
    this.form.password.error = '';
    this.form.repeat_password.data = '';
    this.form.repeat_password.status = '';
    this.form.repeat_password.error = '';
    this.form.new_password.data = '';
    this.form.new_password.status = '';
    this.form.new_password.error = '';
    this.form.repeat_new_password.data = '';
    this.form.repeat_new_password.status = '';
    this.form.repeat_new_password.error = '';
    this.form.captcha.data = '';
    this.form.captcha.status = '';
    this.form.captcha.error = '';
  }

  cancelForgetpassword() {
    this.cancelStatus();
    this.forget_password_modal = false;
  }

  async forgetPassword() {
    if (!this.validator()) {
      return;
    }
    const res: any = await this.$axios.put(`${API.password}/${this.profile.id}`, {
      type: 'reset',
      captcha: this.form.captcha.data,
      new_password: md5(this.form.new_password.data).toUpperCase(),
    });
    if (res.status !== 200) {
      if (res.status == 422) {
        this.$message.error('验证码错误，请重新校验');
      } else {
        this.$message.error(res.message);
      }

      return;
    }
    this.$message.success('修改成功，需重新登录');
    // this.logOnOut(false);
    storage.removeItem('profile');
    this.$router.push('/login');
    this.forget_password_modal = false;
  }

  async sendMessage() {
    const res: any = await this.$axios.post(API.captcha, {
      mobile: this.mobile,
      scene: 'password_reset',
    });
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    // 发送验证码
    const timer = setInterval(() => {
      this.auth_code -= 1;
      if (this.auth_code === 0) {
        clearInterval(timer);
        this.auth_code = 60;
      }
    }, 1000);
    this.form.captcha.data = ''
  }

  async query() {
    this.is_password_loading = true;
    const res: any = await this.$axios.get(API.password);
    this.is_password_loading = false;
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.password = res.data.state ? '******' : '';
  }

  async created() {
    this.profile = storage.getItem('profile') || {};
    
    this.name = (this.userData as any)?.name || '';
    this.mobile = (this.userData as any)?.mobile || '';
    await this.query();
  }
}
